.screenshots {
  padding-bottom: 100px;
  overflow-x: hidden;
}

.container {
  /* max-width: 1280px; */
  padding: 0 4rem;
  margin: 0 auto;
}

.block {
  width: 100%;
  display: grid;
  grid-template-columns: 41% 32% 24%;
  justify-content: center;
  grid-gap: 1rem;
  min-height: 406px !important;
}
.picture1 img,
.picture2 img,
.picture3 img,
.picture4 img {
  border-radius: 1.875rem;
  overflow: hidden;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.picture1 {
  position: relative;
  background-color: transparent;
  border-color: transparent;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 3;
  cursor: pointer;
}

.picture2 {
  position: relative;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}

.picture3 {
  position: relative;
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 3;
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}

.picture4 {
  position: relative;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}

@media (min-width: 1920px) {
  .container {
      max-width: 80%;
  }
}

@media (min-width: 1440px) and (max-width: 1920px) {
  .container {
      max-width: 90%;
  }
}


@media only screen and (max-width: 768px) {
  .screenshots {
    padding-bottom: 80px;
  }

  .container {
    padding: 0 1rem;
  }

  .block {
    grid-template-columns: 41% 32% 24%;
    grid-gap: 0.5rem;
    min-height: 177px !important;
  }
  .picture1 img,
  .picture2 img,
  .picture3 img,
  .picture4 img {
    border-radius: 0.5rem;
    max-width: 768px;
  }
}
